@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&subset=cyrillic,cyrillic-ext,latin-ext");
/*
********************************************************************************
general site structure.
********************************************************************************
*/

@font-face {
    font-family: "bebasneue";
    src: url("font/bebasneue-webfont.eot");
    src: url("font/bebasneue-webfont.eot?#iefix") format("embedded-opentype"), url("font/bebasneue-webfont.woff") format("woff"), url("font/bebasneue-regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: black;
}
* {
    background: transparent;
    font-family: 'PT Sans', Verdana, sans-serif;
    color: #4c4c4c;
}
#sitecontainer {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    
    background: black;
}

#main {
    flex: 1 1 0px;
    align-self: flex-start;

    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
}
#nav {
    flex: 0 0 237px;
    align-self: flex-start;
    height: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;

    background-image: url(img/home_aside_bg_1024.jpg);
    background-repeat: no-repeat;
    background-color: #1b1812;
}
#contents {
    flex: 1 1 0px;
    align-self: flex-start;
    height: 100%;
    /* border-left: 10px solid black; */
}
#indoorviewer {
    width: 100%;
    height: 100%;
}

/*
********************************************************************************
the navigation list-
********************************************************************************
*/
#navlist {
    flex: 1 1 0px;
    min-height: 130px;
    width: 100%;
    padding-top: 30px;
}
#navlist ul {
    list-style-type: none;
    height: 100%;
    margin-left: 10px;
    padding-left: 0;
}
/* #navlist ul li {
    line-height: 1.7em;
} */
#navlist ul li a {
    color: white;
    font-size: 1.4em;
    line-height: 0.9em;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'bebasneue';
    margin-left: 30px;
}
#navlist ul li a:hover {
    text-shadow: 0px 0px 4px #c4c4c4;
}
#navlist ul li.big a {
    line-height: 1.0em;
    font-size: 2.5em;
    margin-left: 10px;
}
#navlist ul li.big a:hover {
    text-shadow: none;
}

/*
********************************************************************************
contact within navigation bar.
********************************************************************************
*/

#contact {
    margin-left: 10px;
    align-self: center;
    flex: 0 0 180px;
    min-height: 180px;
    margin-bottom: 150px;
}
ul.contact {
    list-style-type: none;
    /*margin-left: 10px;*/
    padding-left: 0px;
    font-size: 0.75em;
}
ul.contact > li {
    color: white;
}

ul.contact >li > a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.25em;
}

ul.contact > li > a.mail {
    font-size: 0.9em;
}

/*
********************************************************************************
the logos in the bottom of the navigation bar.
********************************************************************************
*/
#logo_container {
    flex: 0 0 260px;
    text-align: center;
    width: 100%;
    background: transparent;
    padding-bottom: 30px;
}
#logo_container * {
    margin: 0;
    padding: 0;
    background: transparent;
}
/* #logo_container>h1 {
    height: 92px;    
} */
#logo_container>h1>a {
    display: block;
    height: 100%;
    width: 100%;
    text-indent: -5000px;
    margin: 0px auto;
    background-repeat: no-repeat;
    background-position: center;
}
#logo_container > h1:nth-child(1) {
    height: 118px;
}
#logo_container > h1:nth-child(2) {
    height: 118px;
    margin-top: 20px;
}
#logo_container>h1>a.logostation {
    background-image: url(img/STATION-LOGO.png);
}
#logo_container>h1>a.logonm {
    background-image: url(img/DTwin_NM_Logo_inverted_150.png);
}

/*
********************************************************************************
the footer and the links in it.
********************************************************************************
*/
#footer {
    flex: 0 0 37px;
    align-self: flex-start;

    width: 100%;
    /* border-top: 10px solid black; */
    /* height: 37px; */
}
#footer ul {
    float: right;
    margin-top: 5px;
    margin-right: 120px;
    margin-bottom: 0px;
    margin-left: 0px;
}
#footer ul li {
    display: inline;
    margin: 0 10px 0 10px;
}
#footer ul li a {
    font-family: 'PT Sans', Verdana, sans-serif;
    color: #888888;
    text-decoration: none;    
}
#footer ul li a:hover {
    color: white;
}

/*
********************************************************************************
Disclaimer Overlay
********************************************************************************
*/

.disclaimercontainer {
    position: relative;  
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin: 0px auto;
    /*mbt edit: padding*/
    padding: 10px 0;
    z-index: 99;
    background-color:  rgba(0,0,0,.9);
}

.disclaimer {
    position: absolute;
    margin: 10px;
    padding: 25px;
    height: 75%;
    width: 50%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid black;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.disfooter {
    height: auto;
    width: 100%;
}

.disclaimer > p {
    font-size: 0.9rem;
}

.disfooter > .btnAccept {
    align-self: center;
    width: 150px;
    border-radius: 5px;
    height: 30px;
    background-color: lightgray;
    box-shadow: none !important;
    background-image: none;
    outline: 0;
   -webkit-box-shadow: none !important;
}

.btnAccept:hover {
    color: white;
    background-color: #888888;
}

.btnAccept:active {
    color: white;
    background: #1b1812;
}

.hide {
    display: none;
}

/*
********************************************************************************
Scrollbar
********************************************************************************
*/

/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 5px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
